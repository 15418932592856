import { ContentCardSlider } from '@desquared/wave-components-library';
import Link from 'next/link';

export default function SliderSection({ style, shape, cards, title, isMobile, hasPadding, rewind = true }) {
  return (
    <ContentCardSlider
      CardsStyle={style}
      shapedCardsShape={shape}
      Cards={cards}
      title={title}
      isMobile={isMobile}
      hasPadding={hasPadding}
      rewind={rewind}
      hideArrows={!rewind}
      LinkComponent={props => {
        return <Link prefetch={false} href={props.href} {...props} />;
      }}
    />
  );
}
