import { CardsGrid } from '@desquared/wave-components-library';
import Link from 'next/link';
import React from 'react';

export default function Grid({ cards, columns, title, isMobile }) {
  return (
    <CardsGrid
      cards={cards}
      title={title}
      columns={columns}
      isMobile={isMobile}
      LinkComponent={props => {
        return <Link prefetch={false} href={props.href} {...props} />;
      }}
    />
  );
}
