import React from 'react';
import cn from 'classnames';
import styles from './Layout.module.css';
import useWindowUnderThreshold from '../../hooks/useWindowUnderThreshold';
import { setLayout } from './useLayout';

export default function Layout({ props }) {
  const isMobile = useWindowUnderThreshold(900);
  const elements = [];

  const displayItems = items => {
    items.forEach((item, i) => {
      const hpWidthCondition = ![
        'carousel_web_banners',
        'grid_banners',
        'highlights',
        'social_feed',
        'video_banner',
      ].includes(item);

      elements.push(
        <div
          className={cn(
            {
              [styles.hpWidth]: hpWidthCondition,
            },
            { [styles.banner]: item === 'carousel_web_banners' },
            { [styles.videoBanner]: item === 'video_banner' },
            { [styles.grid]: item === 'grid_banners' },
            { [styles.aisle]: item === 'promoted_products' },
            { [styles.hpWidthFeed]: item === 'social_feed' }
          )}
          key={item + '-' + i}
        >
          {setLayout(item, props.dataList[i], isMobile, props.defaultProductImage)}
        </div>
      );
    });
    return elements;
  };
  displayItems(props.layoutList);
  return <div className={cn(styles.layout)}>{elements}</div>;
}
