import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import Layout from '@/components/Layout/Layout';
import { getUserV3 } from '../api/users';
import User from '../store/user';
import { AddressViewSideSheetStore } from '@/store/sideSheet';
import GuestUserStore from '@/store/guestUser';
import { Shop } from '@/store/shop';
import { useRouter } from 'next/router';

import Cookies from 'universal-cookie';

export default function Homepage(props) {
  const cookies = new Cookies();
  const router = useRouter();

  const [guestUser, setGuestUser] = useState({});

  const token = cookies.get('token');

  useEffect(() => {
    const guestUser$ = GuestUserStore.subscribe(setGuestUser);

    async function getCurrentUser(token) {
      const updatedUser = await getUserV3(token);

      // Error case
      if (isEmpty(updatedUser)) return;

      User.update({
        ...updatedUser,
        authToken: token,
      });

      const user = User.getValue();

      if (
        (!user?.addresses || user?.addresses?.length === 0) &&
        !AddressViewSideSheetStore.isShown() &&
        !process.env.hasStoreNecessary
      ) {
        AddressViewSideSheetStore.open();
      }
    }

    if (token) {
      getCurrentUser(token);
    } else {
      const guest = GuestUserStore.getValue();
      const user = User.getValue();
      if (user) {
        User.reset();
        // clearCart();
      }
      if (!isEmpty(guest)) {
        if (!guest?.addresses && guest.email && !AddressViewSideSheetStore.isShown()) {
          AddressViewSideSheetStore.open('');
        }
      }
    }
    return () => {
      guestUser$.unsubscribe();
    };
  }, []);

  return (
    <>
      {/* <Header logo={props.logo} menuList={props.menu} selectedIndex={-1} responsiveLogo={props.responsiveLogo}></Header> */}
      {/* <MobileMenu></MobileMenu> */}
      <Layout props={props} defaultProductImage={props.defaultProductImage} />
    </>
  );
}
