import { getLayoutProps } from '@/utils/layout/layout';
import React, { useContext } from 'react';
import Head from 'next/head';
import Homepage from '@/components/Homepage';
import PageLayout from '@/components/PageLayout';
import { getCMSHomepageGrid, getCmsHomepageLayout } from '@/api/cms';
import { CustomMetaHead } from '@/components/CustomMetaHead/CustomMetaHead';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ModalContext } from '@/contexts/ModalProvider';
import { useTranslations } from 'use-intl';
import DefaultProductImage from '../../public/assets/wave-grocery/Empty-States-Default/DefaultProductImage.png';

export default function Home({ responsiveLogo, layoutList, dataList, metadata, emptyStates }) {
  const router = useRouter();
  const t = useTranslations();
  const { openActionModal, close } = useContext(ModalContext);
  const defaultProdImg = emptyStates?.default_product_image?.url || DefaultProductImage.src;

  useEffect(() => {
    if (router.query.tokenExpired) {
      openActionModal({
        text: t('common.token_expired_modal.text'),
        title: t('common.token_expired_modal.title'),
        hasCenteredText: true,
        actions: [
          {
            btnLabel: t('common.token_expired_modal.close_btn'),
            onClick: () => {
              close();
            },
            btnVariant: 'outlined',
          },
          {
            btnLabel: t('common.token_expired_modal.login_btn'),
            onClick: () => {
              router.push('/login');
              close();
            },
          },
        ],
        img: {
          url: emptyStates?.default_warning?.url,
          ariaLabel: t('common.token_expired_modal.title'),
          size: 'medium',
        },
      });

      const newUrl = router.pathname;
      router.replace(newUrl, undefined, { shallow: true });
    }
  }, [router.query, router.replace, openActionModal, router, t, emptyStates, close]);

  return (
    <>
      <Head>
        <title>{process.env.displayName}</title>
        <meta name="description" content={`App for ${process.env.displayName} by Wave Grocery`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={responsiveLogo} />
      </Head>
      <CustomMetaHead metadata={metadata} />
      <Homepage layoutList={layoutList} dataList={dataList} defaultProductImage={defaultProdImg} />
    </>
  );
}

Home.getLayout = function getLayout(page) {
  return <PageLayout>{page}</PageLayout>;
};

export async function getStaticProps(context) {
  const layoutProps = await getLayoutProps({ headerProps: { selectedIndex: -1 } }, context.locale);

  const { data: layoutData } = await getCMSHomepageGrid('layout', context.locale);
  const home = layoutData.data?.filter(el => el.status === 'live' || !el.status);
  const metadata = home[0]?.content?.metadata;
  const temp = home[0]?.content.layout_subcomponent ?? [];
  let tempList = [];
  for (const el of temp) {
    if (el.enabled) {
      const { data: testData } = await getCmsHomepageLayout(el.layout_sub_schema.id, context.locale);
      tempList.push(testData);
    }
  }
  const layoutList = tempList.map(el => {
    return el.data[0].schema;
  });

  const dataList = tempList.map(el => {
    return { content: el.data[0].content, included: el.included };
  });

  const messages = {
    common: (await import(`../../prebuild-data/i18n/${context.locale}/common.json`)).default,
  };

  return {
    props: {
      ...layoutProps,
      layoutList,
      dataList,
      messages,
      ...(metadata ? { metadata } : null),
    },
  };
}
