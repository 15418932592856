import ProductsAisle from '../ProductsAisle/ProductsAisle';
import Grid from '@/components/Grid/Grid';
import BannerSection from '@/components/BannerSection/BannerSection';
import HighlightsSection from '@/components/Highlights/HighlightsSection';
import SliderSection from '@/components/ContentCardSlider/SliderSection';
import { ContentCardShapeEnum, ContentCardBGEnum } from '@desquared/wave-components-library';
import { setBanner, setGrid, setHighLights, setSlider } from '@/utils/homePageCmsCall';
import { Heading, HeadingEnum, BoldEnum } from '@desquared/wave-components-library';
import { sortArray } from '@/utils/index.js';
import Flockler from '@/components/Flockler/Flockler';

export const setLayout = (schema, item, isMobile, defaultProductImage) => {
  switch (schema) {
    case 'grid_banners':
      return (
        <Grid
          isMobile={isMobile}
          cards={setGrid(item.included.grid_cards)}
          title={item.content.title}
          columns={item.content.columns}
          analytics={{ creative_name: item?.content?.title, creative_slot: 'homepage_grid_banners' }}
        />
      );
    case 'carousel_web_banners':
      let list = sortArray(item.content.banner_items, item.included.homepage_banner_web_item);

      return (
        <BannerSection
          images={setBanner(list)}
          fixedWidth={item.content.fixedWidth}
          analytics={{
            creative_name: 'homepage_carousel',
            creative_slot: 'homepage_carousel',
            promotion_id: `homepage_carousel`,
            promotion_name: `homepage_carousel`,
          }}
        />
      );
    case 'highlights':
      let highlightList = sortArray(item.content.highlights, item.included.highlight);
      return (
        <HighlightsSection
          isMobile={isMobile}
          bgColor={item.lightgreybackground ? 'blue' : 'white'}
          highLightsArray={setHighLights(highlightList)}
          analytics={{ creative_name: item?.content?.title, creative_slot: 'homepage_highlights' }}
        />
      );
    case 'promoted_products':
      const productsSku = item.content.products_andor_offers.map(prod => {
        return prod.replace('PR', '');
      });
      return (
        <>
          <ProductsAisle
            isMobile={isMobile}
            products={productsSku}
            title={item.content.title}
            linkText={item.content.redirection_text}
            productsLink={item.content.redirection_url}
            hasPadding={true}
            limitations={item.content.limitations}
            hideLastTile={!item.content?.redirection_text || item.content?.redirection_text?.length === 0}
            defaultProductImage={defaultProductImage}
          />
        </>
      );

    case 'video_banner':
      return (
        <BannerSection
          images={[
            {
              image: item.content.url,
              mobileImage: item.content.url,
              altText: 'videoBanner',
              key: ' ',
              // href: '#',
            },
          ]}
          fixedWidth={false}
          isVideo={true}
          analytics={{ creative_name: 'homepage_video', creative_slot: 'homepage_video' }}
        ></BannerSection>
      );
    case 'content_slider':
      let listContent = sortArray(item.content.contentcards, item.included.slidercontent);
      let rewind = item?.content?.rewind !== null ? false : true;
      return (
        <SliderSection
          title={item.content.title}
          cards={setSlider(listContent, item.content.image_fill, item.content.opennewtab)}
          shape={fetchStyle(item.content.card_style)}
          style={fetchShape(item.content.card_shape)}
          isMobile={isMobile}
          analytics={{ creative_name: item?.content?.title, creative_slot: 'homepage_content_slider' }}
          rewind={rewind}
        />
      );
    case 'social_feed':
      return (
        <div>
          {!isMobile ? (
            <Heading type={HeadingEnum.HeadingL} bold={BoldEnum.Bold}>
              {item.content.title}
            </Heading>
          ) : (
            <Heading type={HeadingEnum.HeadingS} bold={BoldEnum.Bold}>
              {item.content.title}
            </Heading>
          )}
          {process.env.hasFlockler && item.content.siteuuid && item.content.embeduuid && (
            <Flockler siteuuid={item.content.siteuuid} embeduuid={item.content.embeduuid} />
          )}
        </div>
      );
  }
};

export const fetchShape = s => {
  switch (s) {
    case 0:
      return ContentCardShapeEnum.Squared;
    case 1:
      return ContentCardShapeEnum.Shaped;
    case 2:
      return ContentCardShapeEnum.Horizontal;
    case 3:
      return ContentCardShapeEnum.Vertical;
    case 4:
      return ContentCardShapeEnum.HeaderHorizontal;
  }
};
export const fetchStyle = s => {
  switch (s) {
    case 0:
      return ContentCardBGEnum.temp;
    case 1:
      return ContentCardBGEnum.RightTear;
    case 2:
      return ContentCardBGEnum.DropLeft;
    case 3:
      return ContentCardBGEnum.Rectangle;
    case 4:
      return ContentCardBGEnum.Round;
    case 5:
      return ContentCardBGEnum.DropRight;
    case 6:
      return ContentCardBGEnum.LeftTear;
  }
};
