import { Highlights } from '@desquared/wave-components-library';
import Link from 'next/link';

export default function HighlightsSection({ isMobile, bgColor, highLightsArray }) {
  return (
    <Highlights
      highlightsArray={highLightsArray}
      isMobile={isMobile}
      bgColor={bgColor === 'white' ? 'white' : 'blue'}
      LinkComponent={props => {
        return <Link prefetch={false} href={props.href} {...props} />;
      }}
    ></Highlights>
  );
}
