import Head from 'next/head';

export function CustomMetaHead({ metadata }) {
  if (!Array.isArray(metadata)) return null;

  return (
    <Head>
      {metadata.map((metaItem, index) => {
        switch (metaItem.type) {
          case 'title':
            return <title key={index}>{metaItem.value}</title>;
          case 'description':
            return <meta key={index} name="description" content={metaItem.value} />;
          case 'og:description':
            return <meta key={index} property="og:description" content={metaItem.value} />;
          case 'og:image':
            return <meta key={index} property="og:image" content={metaItem.value} />;
          case 'og:type':
            return <meta key={index} property="og:type" content={metaItem.value} />;
          case 'og:url':
            return <meta key={index} property="og:url" content={metaItem.value} />;
          case 'og:title':
            return <meta key={index} property="og:title" content={metaItem.value} />;
          default:
            return null;
        }
      })}
    </Head>
  );
}
